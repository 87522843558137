<template>
  <div class="my-homework">
    <my-header/>
    <div class="homework-title">
      <div class="homework-title-height">

      </div>
    </div>

    <div class="homework-content-warp">
      <ul class="homework-content">
        <li v-for="(item,index) in questionsData" :key="item.id" class="item">
          <div class="item-left">
            <span class="item-left-number" v-html="courseTitleValue(index)"></span>
            <span class="item-left-name">{{ item.name }}</span>
            <span v-if="item.uF" class="item-left-state">已提交</span>
            <span v-else class="item-left-state item-left-not">未提交</span>
          </div>
          <div class="item-right">
            <span v-if="item.uF" class="item-right-redo remove-blue">已完成</span>
            <span v-else class="item-right-redo remove-blue" @click="goNotFinished(item.id)">去完成</span>
          </div>
        </li>
      </ul>
    </div>

    <my-footer/>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import { getCourse, getQuesList } from '../../api/courseWork/courseWork';

@Component({
    components: {
        myHeader,
        myFooter

    }
})

export default class CourseWork extends Vue {
  // api参数
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase,no-undef
      semester_id: -1,
      student: []
  };

  loading = true;
  questionsData = [];
  semesterList = ['第一学期', '第二学期', '第三学期', '第四学期', '第五学期', '第六学期'];
  semesterStr = '';

  created() {
      const poUrl = localStorage.getItem('loginUrl');
      const loginurl = this.$route.query.url;
      // eslint-disable-next-line eqeqeq
      if (loginurl) {
      // eslint-disable-next-line eqeqeq

          localStorage.setItem('loginUrl', loginurl.toString());
      }
      this.getData();
  }

  getData() {
      getQuesList().then((res) => {
          if (res.code === 200) {
              console.log(res);
              this.questionsData = res.data;
          } else {
              console.log(res.error);
              this.$message.error(res.error);
          }
      }).catch((error) => {
          console.log(error);
      });

      console.log(1);
  }

  // 渲染时自动调用1转换01
  courseTitleValue(index) {
      index++;
      return index >= 10 ? index.toString() : '0' + index;
  }

  goNotFinished(id) {
      this.$router.push({
          path: '/quesAnSheet',
          query: {
              quesId: id

          }
      });
  }
};
</script>

<style lang="scss" scoped>
.my-homework {
  width: 100vw;
  position: relative;
  background-color: #f5f5f6;
  min-height: 100vh;

  .homework-title {
    width: 100%;
    color: #fff;
    background: url('../../assets/images/myHomeWork-bg.png') no-repeat center;
    background-size: cover;

    .homework-title-height {
      height: 156px;

      .title {
        font-size: 14px;
        text-align: center;
      }

      .homework-title-big {
        box-sizing: border-box;
        padding: 33px 0 4px;
        font-size: 18px;
      }
    }
  }

  .no-work {
    padding-bottom: 100px;
    text-align: center;

    .hint {
      margin-top: 175px;
      color: #666666;
    }
  }

  .homework-content-warp {
    position: relative;
    top: -48px;
    width: 1180px;
    margin: 0 auto;
    padding-bottom: 100px;

    .homework-content {
      .item {
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 25px;
        margin-bottom: 20px;
        box-sizing: border-box;
        box-shadow: 0 3px 6px 0 rgba(153, 153, 153, 0.16);
        background-color: #fff;

        .item-left {
          display: flex;
          align-items: center;

          .item-left-number {
            font-family: "PingFang SC", SourceHanSansCN-Medium;
            font-size: 18px;
            font-weight: 500;
            color: #999;
          }

          .item-left-name {
            font-family: "PingFang SC", SourceHanSansCN-Medium;
            font-size: 16px;
            font-weight: 500;
            margin: 0 15px 0 20px;
            color: #181818;
          }

          .item-left-state {
            display: inline-block;
            width: 50px;
            line-height: 20px;
            font-size: 12px;
            text-align: center;
            color: #fff;
            background-color: #2377E3;
          }

          .item-left-wait {
            background-color: #999;
          }

          .item-left-not {
            background-color: #EF4F49;
          }
        }

        .item-right {
          .item-right-score {
            margin-right: 24px;
            font-size: 14px;
            color: #666;

            .score {
              color: #2377E3;
            }
          }

          .item-right-look {
            display: inline-block;
            width: 90px;
            line-height: 32px;
            margin: 0 10px 0 0;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;
            border: 1px solid #2377e3;
            color: #2377e3;

            &:hover {
              color: #fff;
              background-color: #2377E3;
            }
          }

          .item-right-redo {
            display: inline-block;
            width: 84px;
            box-sizing: border-box;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            color: #fff;
            background-color: #2377E3;

            &:hover {
              background-color: #348BEF;
            }
          }
        }

        &:hover {
          .item-left-name {
            color: #2377E3;
          }
        }
      }
    }
  }
}
</style>
